@import "~@styles/variables.scss";
.background {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: $surface-opaque;
  z-index: $loading-z-index;
  user-select: none;

  @media screen and (max-width: $mobile-header-breakpoint) {
    top: $header-height-mobile;
    bottom: $footer-height-mobile;
  }
}

.background.transparent {
  background-color: transparent;
}

.background.noHeader {
  top: 0;
}

.background.noFooter {
  bottom: 0;
}

.animationContainer {
  min-height: 60px;
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: $loading-z-index;
}
