@import "~@styles/variables.scss";
.richText {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $default-text-color;
    font-family: $font !important;
  }
}

.richText.primary {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $primary;
  }
}

.richText.secondary {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $secondary;
  }
}

.richText.success {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $success;
  }
}

.richText.danger {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $danger;
  }
}

.richText.warning {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $warning;
  }
}

.richText.white {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $white;
  }
}

.richText.default {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    color: $default-text-color;
  }
}
