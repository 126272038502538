@import "~@styles/variables.scss";
.button {
  height: 100%;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  overflow-x: hidden;
}

.icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
