@import "~@styles/variables.scss";
.container {
  pointer-events: none;
  &:after {
    font-family: 'Font Awesome 6 Pro';
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
  }
}

.mobileContainer {
  height: $mobile-btn-min-height;
  line-height: $mobile-btn-min-height;
}

.downCaret {
  pointer-events: none;
  &:after {
    font-family: 'Font Awesome 6 Pro';
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 14px;
    content: '\f078';
  }
}

.container.iconBefore {
  &:after {
    left: 10px;
    top: 0px;
  }
}

// Whenever we add another icon type, we must define it here
.container.user {
  &:after {
    content: '\f007';
  }
}
.container.suitcase {
  &:after {
    content: '\f0f2';
    font-weight: 900;
  }
}

.select {
  pointer-events: auto;
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #63686c;
  font-size: 14px;
  line-height: 1.5715;
  height: 100%;
  padding: 5px 24px 5px 11px;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}

.select.iconBefore {
  padding: 5px 11px 5px 30px;
}

.select.mobile {
  height: $mobile-btn-min-height;
}
