@import "~@styles/variables.scss";
.container {
}

.searchContainer {
  margin-top: -30px;
  margin-bottom: 60px;
}

.imageCard {
  height: 300px;
  width: 100%;
  margin-bottom: 10px;

  cursor: pointer;

  &:hover {
    color: $primary-light !important;

    h3,
    h5,
    div,
    i {
      color: $primary-lightest !important;
    }
  }
}

.imageCardIconContainer {
  text-align: center;
}

.imageCardIcon {
  color: white;
  font-size: 88px;
}

.imageCardBody {
  padding-left: 15px;
  padding-right: 15px;
  left: 50%;
  top: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.link {
}

.graphicContainer {
  position: relative;
  display: block;
  height: 500px;
  color: $white;
  width: 100%;
}

.graphicTopLine {
  background-color: $secondary;
  height: 20px;
  width: 70%;
  right: 0px;
  top: 0px;
  position: absolute;
}

.graphicTextBox {
  width: 50%;
  height: 200px;
  right: 50%;
  top: 20px;
  background-color: $primary;
  z-index: 500;
  position: absolute;
  padding: 30px;
}

.scheduleContainer {
  padding-top: 20px;
}

.graphicImageBox {
  width: 70%;
  height: 300px;
  right: 0px;
  top: 20px;
  z-index: 300;
  position: absolute;
}

.scheduleOContainer {
  text-align: right;
}

.scheduleOText {
  font-size: 36px !important;
  line-height: 44px;
}

.scheduleLineContainer {
  text-align: right;
  padding-right: 8px;
}

.scheduleLineText {
  font-size: 36px !important;
  line-height: 44px;
}

.scheduleTimeContainer {
  text-align: left;
}

.scheduleTimeText {
  font-size: 36px !important;
  line-height: 44px;
}

.scheduleDescContainer {
  text-align: left;
}

.scheduleDescText {
  font-size: 20px !important;
  line-height: 28px;
}
