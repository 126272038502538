@import "~@styles/variables.scss";
.container {
  padding-top: 70px;

  @media screen and (max-width: $mobile-header-breakpoint) {
    padding-top: 30px;
  }
}

.header1 {
  @media screen and (max-width: $mobile-header-breakpoint) {
    margin-bottom: 30px !important;
  }
}

.subtitle {
  h2 {
    font-size: 30px !important;
    line-height: 38px !important;
  }
}
.header2 {
  font-size: $xl-text-size;
  line-height: 44px;

  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $large-text-mobile !important;
    line-height: 38px !important;
  }
}
