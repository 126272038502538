@import "~@styles/variables.scss";
.container {
  border-top: 6px solid $primary;
  text-align: left;
  background-color: $white;
}

.imageContainer {
  height: 160px;
  position: relative;
}

.bodyContainer {
  padding: 20px;
  border-left: 0.55px solid $surface;
  border-bottom: 0.55px solid $surface;
  border-right: 0.55px solid $surface;
  height: 200px;
}
