// COLORS

$transparent-heavy: 0.8;
$transparent: 0.5;
$transparent-light: 0.3;

$light: 20%;
$lightest: 50%;
$feint: 70%;

// White
$white: #ffffff;
$white-opaque: transparentize($white, $transparent-light);

// Black
$black: #000000;

// Moment blue
$primary: #182e4d;
$primary-light: lighten($primary, $light);
$primary-lightest: lighten($primary, $lightest);
$primary-feint: lighten($primary, $feint);
$primary-opaque: transparentize($primary, $transparent);
$primary-lightest-opaque: transparentize($primary-lightest, $transparent);
$primary-transparent: #182e4d80;

// Moment red
$secondary: #ad384a;
$secondary-light: lighten($secondary, $light);
$secondary-lightest: lighten($secondary, $lightest);
$secondary-opaque: transparentize($secondary, $transparent);
$on-secondary: $white;
$secondary-transparent: #ad384a80;

// Moment grey
$surface: #e6e7e8;
$surface-extra-dark: darken($surface, 100%);
$surface-dark: darken($surface, 50%);
$surface-almost-dark: darken($surface, 20%);
$surface-light: lighten($surface, 7%);
$surface-lightest: lighten($surface, $lightest);
$surface-opaque: transparentize($surface, $transparent);
$surface-transparent: transparentize($surface, $transparent);
$on-surface: $primary;

// Danger
$danger: #dc3545;
$danger-light: lighten($danger, $light);
$danger-lightest: lighten($danger, 40%);

// Warning
$warning: #a07800;
$warning-light: lighten($warning, $light); // #ddb535;
$warning-lightest: lighten($warning, $lightest); // #f3e5b8;

// Success
$success: #28a745;
$success-light: lighten($success, $light);
$success-lightest: lighten($success, $lightest);

// Default body text is dark grey, Header text is primary
$default-text-color: $surface-dark;

$background-image-gradient: linear-gradient($primary-transparent, $secondary-transparent);

// TYPOGRAPHY
$thin: 300;
$normal: 400;
$bold: 700;

$small-text-size: 10px;
$default-text-size: 14px;
$title-text-size: 16px;
$medium-text-size: 20px;
$large-text-size: 30px;
$xl-text-size: 36px;
$xxl-text-size: 44px;
$huge-text-size: 65px;

$small-text-size-mobile: 10px;
$default-text-size-mobile: 14px;
$title-text-size-mobile: 16px;
$medium-text-size-mobile: 16px;
$large-text-size-mobile: 20px;
$xl-text-size-mobile: 30px;
$xxl-text-size-mobile: 36px;
$huge-text-size-mobile: 44px;

$huge-text: 65px; // h1
$very-large-text: 44px; // h2
$large-text: 36px; // h3
$medium-text: 30px; // h4
$small-text: 20px; // h5
$default-text: 16px; // p changed from 14px to 16px to prevent auto zoom on iOS

$huge-text-mobile: 44px; // h1
$very-large-text-mobile: 36px; // h2
$large-text-mobile: 30px; // h3
$medium-text-mobile: 20px; // h4
$small-text-mobile: 16px; // h5
$default-text-mobile: 16px; // p changed from 14px to 16px to prevent auto zoom on iOS

$font: 'Colfax';

$border-radius: 4px;

// Header and footer layout
$mobile-header-breakpoint: 768px;
$header-height-mobile: 50px;
$header-height-desktop: 75px;

$footer-height-desktop: 219px;
$footer-height-mobile: 229px;

$desktop-btn-min-height: 34px;
$desktop-btn-font-size: 14px;

$mobile-btn-min-height: 42px;
$mobile-btn-font-size: 16px;

$desktop-btn-lg-min-height: 40px;
$desktop-btn-lg-font-size: 16px;

$mobile-btn-lg-min-height: 50px;
$mobile-btn-lg-font-size: 18px;

$modal-header-height-web: 60px;
$modal-title-line-height-web: 40px; // account for padding on top and bottom
$modal-title-font-size-web: 20px;

$modal-header-height-mobile: 60px;
$modal-title-line-height-mobile: 40px; // account for padding on top and bottom
$modal-title-font-size-mobile: 18px;

$alert-modal-footer-height: 110px;

// Z-INDEXES
$modal-z-index: 99999;
$loading-z-index: 9999;

// Form
$form-horizontal-padding: 5px;
$form-element-margin-bottom: 10px;
$form-text-margin-bottom: 16px;
$form-copy-margin-bottom: 12px;

// Spacing
$button-margin-horizontal: 12px;
$button-margin-vertical: 12px;
