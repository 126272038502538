@import "~@styles/variables.scss";
.mobileOnly {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
    width: calc(100% + 40px) !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
}

.noMargin {
  margin: 0px;
}
