@import "~@styles/variables.scss";
.container {
  border-top: 6px solid $primary;
  border-bottom: 1px solid $surface;
  border-right: 1px solid $surface;
  border-left: 1px solid $surface;
  padding: 5px 10px;
  background-color: $white;
  z-index: 9999;
  width: 100%;
}
