@import "~@styles/variables.scss";
.base {
  font-size: 18px !important;
  height: 46px !important;
}

.base.desktop {
  @media (max-width: 767px) {
    display: none;
  }
}

.base.mobile {
  @media (min-width: 768px) {
    display: none;
  }
}
