@import "~@styles/variables.scss";
.PageFooter {
  height: $footer-height-desktop;
  background: $surface-light;
  color: $white;
  width: 100%;
  overflow: hidden;
  position: static;
  bottom: 0;
  z-index: 1001;

  @media screen and (max-width: $mobile-header-breakpoint) {
    height: $footer-height-mobile;
  }
}

.NavLinkSection {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 7px;
  border-top: solid 3px $surface;
}

.NavLinkSectionTitle {
  margin-top: 6px;
  margin-bottom: 6px;
  @media screen and (max-width: $mobile-header-breakpoint) {
    margin-bottom: 0px;
  }
}

.NavContactSection {
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
}

.NavContactSectionText {
  padding-top: 8px;
  @media screen and (max-width: $mobile-header-breakpoint) {
    padding-top: 0px;
  }
}
.FooterLink {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  color: $primary;

  a {
    font-weight: $thin;
    color: $default-text-color;

    &:active {
      color: #305c9b;
    }

    &:hover {
      color: #305c9b;
    }
  }

  @media screen and (max-width: $mobile-header-breakpoint) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.FooterLink.strong {
  a {
    font-weight: $bold;
    color: $primary;

    &:active {
      color: #305c9b;
    }

    &:hover {
      color: #305c9b;
    }
  }
}
