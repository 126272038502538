@import "~@styles/variables.scss";
.base {
}

.base.surfaceColor {
  color: $surface;
}

.base.primaryColor {
  color: $primary;
}

.base.secondaryColor {
  color: $secondary;
}

.base.successColor {
  color: $success;
}

.base.dangerColor {
  color: $danger;
}

.base.warningColor {
  color: $warning;
}

.base.whiteColor {
  color: $white;
}

.base.disabled {
  color: $light;
}

.base.enabled {
  color: $primary;
}

.base.beforeText {
  margin-right: 10px;
}

.IconForLabel {
  paddingleft: 0px;
  text-align: left;
}
