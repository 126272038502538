@import "~@styles/variables.scss";
.container {
  min-height: calc(100vh - #{$footer-height-desktop} - #{$header-height-desktop});

  @media screen and (max-width: $mobile-header-breakpoint) {
    min-height: calc(100vh - #{$footer-height-mobile} - #{$header-height-mobile});
  }

  width: 100%;
  display: flex;
  flex-direction: column;
}

.containerImageHeader {
  min-height: calc(100vh - #{$footer-height-desktop});

  @media screen and (max-width: $mobile-header-breakpoint) {
    min-height: calc(100vh - #{$footer-height-mobile});
  }

  width: 100%;
  display: flex;
  flex-direction: column;
}
