@import "~@styles/variables.scss";
.container {
  padding-left: 8px;
  padding-right: 24px;
  overflow-x: hidden;
  line-height: 1.3;
}

.icon {
  color: $primary;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
