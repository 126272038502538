@import "~@styles/variables.scss";
.container {
  pointer-events: none;
  &:after {
    content: '\f017';
    font-family: 'Font Awesome 6 Pro';
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 10px;
    top: 6px;
  }
}

.select {
  pointer-events: auto;
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #63686c;
  font-size: 14px;
  line-height: 1.5715;
  height: 100%;
  padding: 5px 11px 5px 11px;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}
