@import "~@styles/variables.scss";
.Header {
  min-height: 75px;
  position: absolute;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 998;

  @media screen and (max-width: $mobile-header-breakpoint) {
    padding-top: 9px;
    padding-bottom: 9px;
    background: rgba(230, 231, 232, 0.5);
    min-height: $header-height-mobile;
    position: fixed;
  }
}

.HeaderWrapper {
  min-height: $header-height-desktop;

  @media screen and (max-width: $mobile-header-breakpoint) {
    min-height: $header-height-mobile;
  }
}

.Header.primary {
  background-color: $primary;

  @media screen and (max-width: $mobile-header-breakpoint) {
    background-color: $surface-opaque;
  }
}

.NavBar {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
}

.NavBtn {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 24px;

  border-radius: $border-radius;
  text-align: center;
  width: 90px;
  height: 36px;
  cursor: pointer;

  font-size: 16px;

  &:hover {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: $mobile-header-breakpoint) {
    display: none;
  }
}

.NavBtn.primary {
  background: $primary;
  color: $white;
  border-color: $primary;

  &:hover {
    background: #305c9b !important;
  }
}

.NavBtn.white {
  background: $white;
  color: $primary;
  border-color: $white;

  &:hover {
    background: $surface;
  }
}

.NavBtnLink {
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
}

.NavLink {
  color: $white;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  min-width: 74px;

  &.active {
    color: #15cdfc;
  }

  a {
    color: $white;
    cursor: pointer;
  }
}

.LogoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;

  @media screen and (max-width: $mobile-header-breakpoint) {
    align-self: center;
    justify-content: flex-end;
    width: 100vw;
  }
}

.NavButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //margin-right: -24px;
  width: 100vw;
  white-space: nowrap;

  @media screen and (max-width: $mobile-header-breakpoint) {
    display: none;
  }
}

.NavMenu {
  z-index: 9998;
  @media screen and (min-width: $mobile-header-breakpoint + 1) {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes growUp {
  0% {
    transform: scaleY(1);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(0);
  }
}

.NavMenuBody {
  position: fixed;
  top: $header-height-mobile;
  left: 0px;
  background: $primary;
  //animation: fadeIn 0.2s ease-in-out;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  padding: 10px 15px;
}

.NavMenuBody.open {
  //animation: growUp 300ms ease-in-out forwards;
}

.NavMenuBtn {
  height: 32px;
  width: 42px;
  border: $primary solid 1px;
  border-radius: $border-radius;
  color: $primary;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.NavMenuBtn.open {
  border-color: $white;
  color: $white;
  background: $primary;
}

.hideOnMobile {
  @media screen and (max-width: $mobile-header-breakpoint) {
    display: none;
  }
}

.hideOnDesktop {
  @media screen and (min-width: $mobile-header-breakpoint + 1) {
    display: none;
  }
}

.NavMenuItem {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  background: $primary;
  color: $white;
}
