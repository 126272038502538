@import "~@styles/variables.scss";
.header {
  color: $default-text-color;
}

.header.primary {
  color: $primary;
}

.header.secondary {
  color: $secondary;
}

.header.success {
  color: $success;
}

.header.danger {
  color: $danger;
}

.header.warning {
  color: $warning;
}

.header.white {
  color: $white;
}

.header.default {
  color: $default-text-color;
}

.header.strong {
  font-weight: $bold;
}

.header.thin {
  font-weight: $thin;
}

.header.size1 {
  font-size: $huge-text-size;
  line-height: $huge-text-size + 8px;
}

.header.size2 {
  font-size: $xxl-text-size;
  line-height: $xxl-text-size + 8px;
}

.header.size3 {
  font-size: $xl-text-size;
  line-height: $xl-text-size + 8px;
}

.header.size4 {
  font-size: $large-text-size;
  line-height: $large-text-size + 8px;
}

.header.size5 {
  font-size: $medium-text-size;
  line-height: $medium-text-size + 8px;
}

.header.sizep {
  font-size: $title-text-size;
  line-height: $title-text-size + 8px;
}

.header.size1.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $huge-text-mobile;
    line-height: $huge-text-mobile + 8px;
  }
}

.header.size2.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $very-large-text-mobile;
    line-height: $very-large-text-mobile + 8px;
  }
}

.header.size3.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $large-text-mobile;
    line-height: $large-text-mobile + 8px;
  }
}

.header.size4.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $medium-text-mobile;
    line-height: $medium-text-mobile + 8px;
  }
}
.header.size5.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $small-text-mobile;
    line-height: $small-text-mobile + 8px;
  }
}
.header.sizep.responsive {
  @media screen and (max-width: $mobile-header-breakpoint) {
    font-size: $default-text-mobile;
    line-height: $default-text-mobile + 8px;
  }
}
